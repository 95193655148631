
.mainPrize {
    display: inline-block;
    text-align: center;
    max-width: 90%;
}

.grid-1 { 
    width: fit-content;
    justify-items: center;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    grid-column-gap: 2rem;
    padding-left: 5rem;
    padding-right: 5rem;
}

.grid-subPrice {
    align-items: left;
    text-align: center;
}


@media(max-width: 768px) {

    .grid-1 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        padding: 2rem;
    }
}

