
/*intro*/

.containerIntro-ourPartners {
    background-image: linear-gradient(
                     rgba(0, 0, 0, .5), 
                     rgba( 0, 0, 0, .5)),
                     url("../../images/junction_img/OurPartnersCoverImg.jpg");
}

/*content*/

.container3 { 
    padding-left: 4rem;
    padding-right: 4rem;
    display: flex;
    flex-direction: column;
}

.container3-row { 
    align-items: center;
    display: flex; 
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    padding: 1rem;
    padding-left: 11rem;
}

.container3-title { 
    color:  #35e2df;
    max-width: 20rem;
    text-align: left;
    padding: 1rem;
}

.container3-textContent { 
    width: 55%;
    text-align: left;
    padding: 1rem;
}

.partnerLogo { 
    width: 260px;
    height: 110px;
    padding: 1rem;
}


@media(max-width: 800px) {

    .container3 { 
        justify-content: center;
        padding: 0;
    }

    .container3-title { 
        text-align: center;
        justify-content: center;
    }

    .container3-row { 
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-left: 1rem;
    }

    .partnerLogo { 
        max-height: 100px;
        min-width: 100px;
        justify-content: center;
    }

    .container3-textContent { 
        text-align: center;
        justify-content: center;
        width: 80%;
    }
}