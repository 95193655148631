
/*intro*/

.containerIntro-forParticipants{
    background-image: linear-gradient(
                     rgba(0, 0, 0, .5), 
                     rgba( 0, 0, 0, .5)),
                     url('../../images/junction_img/ForParticipantsCoverImg.jpg');
}

/*content:*/

.junctionXLogo-forParticipants {
    height: 400px;
    padding: 20px;
}

.junctionXLogo-forParticipants:hover {
    transform: scale(1.03);
    transition: all 300ms;
}


@media(max-width: 800px) {

    .junctionXLogo-forParticipants {
        height: 250px;
    }
}