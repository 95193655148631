
/* centered Intro */

.containerIntro-asia2020{
    background-image: linear-gradient(
                     rgba(0, 0, 0, .0), 
                     rgba(0, 0, 0, .0)),
                     url("../../../../images/JxAsia2020/JxAsiaCoverImg.png");
}

.header1introCentered {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 40px;
    text-align: center;
}

.header2introCentered { 
    text-align: center;
}

.jxasia2020 {
    background-color: black;
}

.junctionXAsialogo {
    text-align: center;
    justify-content: center;
    width: 700px;
}


/*content*/

.partnerList {
    display: flex;
    flex-direction: column;
    align-items: center;
}


@media(max-width: 800px) {

    .containerIntro-asia2020 { 
        padding-top: 40%;
    }
}