
/* Container Intro Section CSS */
.containerIntro-team{
    background-image: linear-gradient(
                     rgba(0, 0, 0, .5), 
                     rgba(0, 0, 0, .5)),
                     url("../../images/junction_img/team.jpg");
}

/* Container Team Head of JunctionX Section CSS */
.container-team-head {
    padding: 5rem;
    text-align: center;
}

/* Partnership Section CSS */
.container-team-partnership {
    padding: 5rem;
    text-align: center;
}

/* Marketing Section CSS */
.container-team-marketing {
    padding: 5rem;
    text-align: center;
}

/* Web Development Section CSS */
.container-team-web {
    padding: 5rem;
    text-align: center;
}

/* Head of Department CSS*/
.teamHead {
    font-size: 15px;
    display: flex;
    justify-content: space-around;
    padding: 40px;
    max-width: 700px;
    margin: auto;
    flex-wrap: wrap;
}

/* Team Members of Department CSS */
.team-members {
    font-size: 15px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 700px;
    margin: auto;
}


/* Display Picture for Members CSS */
.avatar {
    object-fit: cover;
    object-position: 100% 0;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    opacity: 1;
    padding: 20px;
}

/* Title CSS */
.position {
    font-weight: 600;
}

/* Email CSS */
.email {
    font-size: inherit;
}

@media(max-width: 768px) {
    .avatar {
        object-fit: cover;
        object-position: 100% 0;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        display: inline-block;
        opacity: 1;
        margin-top: 40px;
    }

    .teamHead {
        padding: 0;
    }
}