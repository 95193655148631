.containerRegistration {
    padding: 10rem;
    text-align: center
}

.register-link {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 5rem;
    margin-top: 6rem
}

.register-individual {
    position: relative;
}

.register-team {
    position: relative;
}

img {
    max-width: 100%;
    object-fit: contain;
    text-align: center;
    opacity: 0.5;
    border: 1px solid #35e2df;
}

.content-register {
    position: absolute;
    width: 100%;
    z-index: 1;
}

.paragraph-register {
    padding: 2rem;
}

.textTwo {
    line-height: 2.9rem;
}


/*Button*/

@mixin color($color) {
    background-color: $color;
    border: 2px solid $color;
    &:hover {
        background-color: white;
        color: $color;
    }
}

.center {
    text-align: center;
}

.btn {
    color: white;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    letter-spacing: .08rem;
    margin: 1.8rem .8rem;
    padding: 1rem 2rem;
    transition: all .4s;
    text-transform: uppercase;
    &--orange {
        @include color(#F79E02);
    }
    &--cyan {
        @include color(#5CDB95);
    }
    &--blue {
        @include color(#97CAEF);
    }
    &--pink {
        @include color(#F64C72);
    }
    &--black {
        @include color(#112D32);
    }
    &--red {
        @include color(#C60021);
    }
    &--gray {
        @include color(#CDCDCD);
    }
}

@media(max-width: 768px) {
    .containerRegistration {
        padding: 2rem;
        text-align: center;
        padding-top: 6rem
    }
    .register-link {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 0rem;
        margin-top: 2rem;
    }
    .content-register {
        position: relative;
    }
    .paragraph-register {
        padding: 0em;
    }
}