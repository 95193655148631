.container {
    background: #232323;
    width: 100%;
    display: inline-block;
    justify-content: center;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 10px;
    margin-top: 5rem;
    margin-bottom: 5rem;
    width: 100%;
}

.schedule {
    padding-left: 0px;
}

.shiftdown {
    margin-top: 10rem
}

.grid-sub {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.grid-sub>p:nth-child(even) {
    text-align: left;
    font-family: 'Lato';
}

@media(max-width: 768px) {
    .gridContainer {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 10px;
        margin-top: 0rem;
        width: 100%;
    }
    .grid-block {
        padding-right: 40px;
    }
    .schedule {
        padding-left: 40px;
    }
}