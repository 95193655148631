#lab_social_icon_footer {
    padding: 3rem;
    background-color: #dedede;
}

#lab_social_icon_footer a {
    color: #333;
    padding: 0.1rem;
}

#lab_social_icon_footer #social-fb:hover {
    color: #3B5998;
}

#lab_social_icon_footer #social-linkedin:hover {
    color: #4099FF;
}

#lab_social_icon_footer #social-insta:hover {
    color: #d34836;
}

#lab_social_icon_footer #social-em:hover {
    color: #f39c12;
}

.container-footer {
    width: 100%;
    color: black;
    justify-content: center;
    text-align: center;
}


.social-links{
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    text-align: center;
}

@media(max-width: 768px){

    #lab_social_icon_footer{
        padding: 1rem
    }
}

@media(max-width: 375px){
    .container-footer {
        font-size: 12px
    }
}